.MuiTableCell-numeric-42 {
  /* text-align: left !important; */
  flex-direction: row !important;
}

.MuiTableCell-paddingNone-189 {
  padding: 0 20px !important;
}

.table-body td {
  text-align: left;
}

.table-head {
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  color: grey;
  font-size: 0.8125rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
}

.table-row {
  width: 100%;
  min-height: 48px;
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 0.8125rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
}

.bb {
  border-bottom: solid 1px lightgrey;
}

.table-row-edit {
  background-color: #c0c1c4;
  color: white;
  padding: 1px 15px;
  border-radius: 3px;
  box-shadow: none;
  border: none;
  color: white;
  cursor: pointer;
}

.table-row-edit:hover {
  background-color: #92cf5c;
}
