.nav-text {
    color: #ebe9e9;
    font-size: 12px;
    font-weight: bold;
}
.dashboard:hover{
  color: 'red'
}
.left-navbar {
    background-color: #008eff;
    overflow-y: scroll;
    padding-top: 60px;
}

.menu-link-item {
    height: 48px;
    padding-left: 5px;
    border-bottom: 1px solid #e6e9ec;
    cursor: pointer;
    border-bottom: 1px solid #2196F3
}
.menu-link-item:last-child{
  border-bottom: none;
}
.jchXFh{
  color: white;
  width: 7px!important;
  height: 7px!important;
  margin-right: 15px;
}

.kohDzk{
  color: white;
  width: 7px!important;
  height: 7px!important;
  margin-right: 15px;
}

.jMpiDs{
  padding-left: 0!important; 
  border-bottom: 1px solid #2196F3;
}

.gzbwsk{
  padding-left: 0!important; 
  border-bottom: 1px solid #2196F3;
}
.gzbwsk a{
  border-bottom: none;
  height: 100%;
}
.menu-link-item:hover{
  box-shadow: inset 5px 0 0 0px #29b6f6;
}

.menu-link-item .nav-text:hover {
  color: #29b6f6;
}

.menu-link-wrapper-active{
  box-shadow: inset 5px 0 0 0px #29b6f6;
}

.menu-link-wrapper-active .nav-text{
  color: #fff;
}



/* position: fixed;
width: 256px;
height: 100%;
-webkit-box-shadow: 0 0 7px 0 rgba(172, 181, 195, 0.3);
-webkit-transition: 0.5s;
-o-transition: 0.5s;
transition: 0.5s;
background-color: #2d3750; */
