
  
  @media only screen and (max-width: 991px){
    .header-wrapper{
      display: none;
    }
    .footer{
      display: none;
    }
    .ipad-header{
      display: flex! important;
    }
    .left-navbar-wraper{
      display: block;
    }
  }
