.ql-toolbar.ql-snow {
  border: 1px solid #a9a5a5;
}

.ql-container.ql-snow {
  border-radius: 0 0 5px 5px;
  border: 1px solid #a9a5a5;
  padding: 5px;
  font-size: 15px;
}

.ql-editor {
  min-height: 240px;
}

.ql-editor ol, .ql-editor ul {
  padding-left: 0;
}