.fakeplayer {
  width: 320px;
  height: 40px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  padding: 0;
  margin: auto;
  background-color: whitesmoke;
  border-radius: 20px;
}
